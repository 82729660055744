import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen} from "../_metronic/layout";

const ECommercePage = lazy(() => import("./modules/ECommerce/pages/eCommercePage"));
const Doctorapp = lazy(() => import("../Components/all_routes/Routes"));
const Doctorassistant = lazy(() => import("../Components/all_routes/DoctorAssistantPage"));
const Claimant = lazy(() => import("../Components/all_routes/ClaimantFormPage"));
const Frontdesk = lazy(() => import("../Components/all_routes/Frontdeskpage"));
const DashboardPage1 =lazy(()=>import("../app/pages/DashboardPage"));

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <Route path="/dashboard" component={DashboardPage1} />
        <Route path="/Masters" component={ECommercePage} />
        <Route path="/doctor-app" component={Doctorapp} />
        <Route path="/doctor-asst" component={Doctorassistant} />
        <Route path="/frontdesk" component={Frontdesk} />
        <Route Path="/claimant" component={Claimant}/>
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
