/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage, actionTypes } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import Cookies from "js-cookie";
import { useEffect } from "react";
import QrPage from "./modules/Auth/pages/QrPage";

export function Routes() {
  const dispatch = useDispatch();
  function checkSessionExpiration() {
    const cookie = Cookies.get("session_id");
    if (cookie === undefined) {
      dispatch({ type: actionTypes.ClearUser });
    }
  }
  useEffect(() => {
    checkSessionExpiration();
  }, []);
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );

  return (
    <Switch>
      <Route path="/public" component={QrPage} />
      {!isAuthorized ? (
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}
      {/* <Redirect to="error/error-v1" /> */}
      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />
      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
    </Switch>
  );
}
