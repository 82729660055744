import axios from "axios";
import { HOST } from "../../../../container/dr_examination/Redux/DoctorConstant";

export const LOGIN_URL = `${HOST}/api1/auth/login`;
export const REGISTER_URL = `${HOST}/api/auth/register`;
export const REQUEST_PASSWORD_URL = `${HOST}/api/password/forgot`;
export const LOGOUT_URL = `${HOST}/api/auth/logout`;

export const ME_URL = `${HOST}/auth/me`;

export function login(email, password) {
  return axios.post(LOGIN_URL, { email, password });
}
export function qrpage(email, password) {
  return axios.post(LOGIN_URL, { email, password });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email }, { withCredentials: true });
}

export async function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return await axios.get(ME_URL, { withCredentials: true, credentials: "include" }).then();
}
export function logout() {
  return axios.post(LOGOUT_URL, { withCredentials: true, credentials: "include" });
}
