/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import * as auth from "../_redux/authRedux";
import { getUserByToken, qrpage } from "../_redux/authCrud";
import Cookies from "js-cookie";
import { actionTypes } from "../_redux/authRedux";
import { useDispatch } from "react-redux";
import { HOST } from "../../../../container/dr_examination/Redux/DoctorConstant";

const QrPage = (props) => {
  const [loading, setLoading] = useState(true); // Set loading to true initially
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  function checkSessionExpiration() {
    const cookie = Cookies.get("session_id");
    if (cookie === undefined) {
      dispatch({ type: actionTypes.ClearUser });
    }
  }
  useEffect(() => {
    checkSessionExpiration();
  }, []);



  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const username = params.get("usr");
    const password = params.get("pwd");
    if (username && password) {
      handleLogin(username, password);
    } else {
      // Redirect to login page if no parameters found
      history.push("/auth/login");
    }
  }, [history, location.search]);

  const handleLogin = async (username, password) => {
    try {
      const {
        data: { token, user, session_id, section_no },
      } = await qrpage(username.toLowerCase(), password, {
        withCredentials: true,
        credentials: "include",
      });

      if (HOST === "http://localhost:8080") {
        Cookies.set("accessToken", token.accessToken, { sameSite: "none", secure: true });
        Cookies.set("userId", user.id, { sameSite: "none", secure: true });
        Cookies.set("userRole", user.role, { sameSite: "none", secure: true });
        Cookies.set("userTypeId", user.userTypeId, { sameSite: "none", secure: true });
        Cookies.set("session_id", session_id, { sameSite: "none", secure: true });
        Cookies.set("section_no", section_no, { sameSite: "none", secure: true });
        Cookies.set("refreshToken", token.refreshToken);
      } else if (HOST === "https://ap1.imeapp.in") {
        Cookies.set("accessToken", token.accessToken, { sameSite: "none", secure: true, domain: ".imeapp.in", path: "/" });
        Cookies.set("userId", user.id, { sameSite: "none", secure: true, domain: ".imeapp.in", path: "/" });
        Cookies.set("userRole", user.role, { sameSite: "none", secure: true, domain: ".imeapp.in", path: "/" });
        Cookies.set("userTypeId", user.userTypeId, { sameSite: "none", secure: true, domain: ".imeapp.in", path: "/" });
        Cookies.set("session_id", session_id, { sameSite: "none", secure: true, domain: ".imeapp.in", path: "/" });
        Cookies.set("refreshToken", token.refreshToken, { sameSite: "none", secure: true, domain: ".imeapp.in", path: "/" });
        Cookies.set("section_no", section_no, { sameSite: "none", secure: true, domain: ".imeapp.in", path: "/" });
      } else if (HOST === "https://api.delicatoimeapp.com") {
        Cookies.set("accessToken", token.accessToken, { sameSite: "none", secure: true, domain: ".delicatoimeapp.com", path: "/" });
        Cookies.set("userId", user.id, { sameSite: "none", secure: true, domain: ".delicatoimeapp.com", path: "/" });
        Cookies.set("userRole", user.role, { sameSite: "none", secure: true, domain: ".delicatoimeapp.com", path: "/" });
        Cookies.set("userTypeId", user.userTypeId, { sameSite: "none", secure: true, domain: ".delicatoimeapp.com", path: "/" });
        Cookies.set("session_id", session_id, { sameSite: "none", secure: true, domain: ".delicatoimeapp.com", path: "/" });
        Cookies.set("refreshToken", token.refreshToken, { sameSite: "none", secure: true, domain: ".delicatoimeapp.com", path: "/" });
        Cookies.set("section_no", section_no, { sameSite: "none", secure: true, domain: ".delicatoimeapp.com", path: "/" });
      } else if (HOST === "https://api.assesseaseime.com") {
        Cookies.set("accessToken", token.accessToken, { sameSite: "none", secure: true, domain: ".assesseaseime.com", path: "/" });
        Cookies.set("userId", user.id, { sameSite: "none", secure: true, domain: ".assesseaseime.com", path: "/" });
        Cookies.set("userRole", user.role, { sameSite: "none", secure: true, domain: ".assesseaseime.com", path: "/" });
        Cookies.set("userTypeId", user.userTypeId, { sameSite: "none", secure: true, domain: ".assesseaseime.com", path: "/" });
        Cookies.set("session_id", session_id, { sameSite: "none", secure: true, domain: ".assesseaseime.com", path: "/" });
        Cookies.set("refreshToken", token.refreshToken, { sameSite: "none", secure: true, domain: ".assesseaseime.com", path: "/" });
        Cookies.set("section_no", section_no, { sameSite: "none", secure: true, domain: ".assesseaseime.com", path: "/" });
      }

      props.login(user, token);
      await getUserByToken().then(history.push("/dashboard"));
    } catch (error) {
      history.push("/auth/login");
    } finally {
      setLoading(false); // Set loading to false after login attempt
    }
  };

  // Display Bootstrap spinner while loading is true
  return (
    loading && (
      <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    )
  );
};

export default connect(null, auth.actions)(QrPage);
// export default injectIntl(connect(null, auth.actions)(QrPage));
