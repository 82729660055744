/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Profile4 from "../../../../../Image/Profile4.png";
import Cookies from "js-cookie";

export function QuickUser() {
  const history = useHistory();
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const userRoleFromCookies = Cookies.get("userRole");
  const logoutClick = () => {
    const toggle = document.getElementById("kt_quick_user_toggle");
    if (toggle) {
      toggle.click();
    }
    localStorage.removeItem("selectedDoctor1")
    localStorage.removeItem("selectedDate")
    localStorage.removeItem("selectedDoctor")
    history.push("/logout");
  };
  const userRole = (user) => {
    let displayRole;
    if (user) {
      if ((user.role === "Admin" || user.role === "Developer") && user.super === true) {
        displayRole = "SuperAdmin";
      } else if (user.role === "DoctorAssistant") {
        displayRole = "FrontDesk";
      } else if (user.role === "FrontDesk") {
        displayRole = "Associate";
      } else {
        displayRole = user.role;
      }
    }
    return displayRole;
  };
  return (
    <div id="kt_quick_user" className="offcanvas offcanvas-right offcanvas p-10 " style={{ zIndex: "1003" }}>
      <div className="offcanvas-header d-flex ">
        <a href="#" className="btn btn-xs btn-icon btn-light btn-hover-primary align-items-center justify-content-center mr-4" id="kt_quick_user_close">
          {/* <i className="ki ki-close icon-xs text-muted" /> */}
          <i className="fas fa-chevron-right"></i>
        </a>
        <h3 className="font-weight-bold">
          User Profile
          {/* <small className="text-muted font-size-sm ml-2">12 messages</small> */}
        </h3>
      </div>
      <hr></hr>
      <div className="offcanvas-content pr-5 mr-n5">
        <div className="d-flex align-items-center mt-5">
          <div className="symbol symbol-100 mr-5">
            <div
              className="symbol-label"
              style={{
                backgroundImage: `url(${Profile4})`,
              }}
            />
            {/* <i className="symbol-badge bg-success" /> */}
          </div>
          <div class="d-flex flex-column align-items-start justify-content-start">
            <a href="#" class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary mb-2">
              {user && user.email ? user.email : ""}
            </a>
            <div class="text-muted mb-2">{userRole(user)}</div>
            <span>
              {userRoleFromCookies !== "claimant" && (
                <button class="btn btn-light-primary btn-bold mr-2" href="" onClick={() => history.push("/doctor-app/profile")}>
                  Update Profile
                </button>
              )}
              <button class="btn btn-light-primary btn-bold" onClick={logoutClick}>
                Sign out
              </button>
            </span>
          </div>
        </div>
        <hr></hr>
        {/* <div className="separator separator-dashed mt-8 mb-5 border-4" /> */}
      </div>
    </div>
  );
}
