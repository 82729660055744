import React from "react";

const AnchorBtn = ({ onClick, children, type = "button", disabled,className = "", style = {} }) => {
    return (
      <button
        type={type}
        onClick={onClick}
        disabled={disabled}
        className={`button ${className}`}
        style={{
          background: "none",
          border: "none",
          padding: 0,
          margin: 0,
          opacity: disabled ? 0.5 : 1,
          cursor: "pointer",
          fontSize: style?.fontSize || "",
          fonweight: style?.fonweight || "",
          color: style?.color || "#1B96BC",
          ...style,
        }}
      >
        {children}
      </button>
    );
  };
export default AnchorBtn;
//
